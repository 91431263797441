import React from 'react'
import './index.scss'
import ButtonComponent from '@components/buttonComponent'
import { Container } from 'react-bootstrap'

const NotFoundPage = () => {
  return (
    <Container className="not-found-root">
      <title>Not found</title>
      <h5>ERROR 404</h5>
      <h1>Page Not Found</h1>
      <p>
        The page you are looking for does not exist. It may have been moved, or
        removed altogether. Perhaps you can return back to the site's homepage
        and see if you can find what you are looking for.
      </p>
      <ButtonComponent
        button={{
          title: 'Go home',
          type: 'secondary',
          slug: { current: '/' },
        }}
      />
    </Container>
  )
}

export default NotFoundPage
